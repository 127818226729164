import { getColumnDisplayName, sentenceCase } from "../utils";
import { IconButton } from "../elements/IconButton";
import StarsIcon from "../svgs/Stars";
import backend_services from "src/services/backend_service";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "src/context/AuthContext";
import { useParams } from "react-router-dom";
import { SimpleSpinner } from "../elements/Spinner";
import useDefaultTable, { Column } from "src/hooks/useChatTable";
import useChatDetails from "src/hooks/useChatDetails";
import { twMerge } from "tailwind-merge";
import { KurationTool } from "src/models/tools";
import { ColumnsTextArea } from "src/pages/filters/ColumnsTextArea";
import {
  FilterFields,
  FilterFormField,
  getUpdatedForm,
} from "../preCuratedLists/FilterFields";
import PlusIcon from "../svgs/Plus";
import { FreeDict } from "src/models/data";
import { parseFilterForm } from "src/pages/filters/filterUtils";

export function ColumnForm({
  selectedTool,
  columnsFallback,
  onSuccess,
  preventColumnCreation,
}: {
  selectedTool: KurationTool;
  columnsFallback: Column[];
  onSuccess: ({
    tool,
    args,
    conditions,
  }: {
    tool: KurationTool;
    args: FreeDict;
    conditions: FreeDict[];
  }) => void;
  preventColumnCreation?: boolean;
}) {
  const { chatId } = useParams();
  const { chatDetails } = useChatDetails(chatId ?? null);

  const tableId = chatDetails?.table_id;

  const { data: defaultTable } = useDefaultTable(tableId);
  const columns = defaultTable?.column_list || columnsFallback;
  const [conditionalColumn, setConditionalColumn] = useState(false);
  const [columnForm, setColumnForm] = useState<{
    [key: string]: string | null;
  }>(selectedTool.fields.reduce((acc, f) => ({ ...acc, [f.id]: null }), {}));

  const [errors, setErrors] = useState<string[]>([]);
  const { user } = useAuth();

  const [addColumnLoading, setAddColumnLoading] = useState(false);
  const [filterForm, setFilterForm] = useState<FilterFormField[]>([]);

  async function add_column(args: {
    [key: string]: string | null | Array<FreeDict>;
  }) {
    if (!user || !selectedTool) {
      return;
    }
    if (Object.values(args).some((value) => !value)) {
      const newErrors: string[] = [];
      Object.entries(args).forEach((e) => {
        if (!e[1]) {
          newErrors.push(e[0]);
        }
      });

      setErrors(Array.from(new Set(newErrors)));
      return;
    }
    const columnConditions = parseFilterForm(
      filterForm,
      defaultTable?.column_list || [],
    );
    setAddColumnLoading(true);
    if (preventColumnCreation) {
      setAddColumnLoading(false);
      // user must be selecting a tools instead of creating a column
      onSuccess({ tool: selectedTool, args, conditions: columnConditions });
      return;
    }
    await backend_services
      .fetchProtectedData(
        `/chat/${chatId}/column/${selectedTool.id}`,
        user?.getIdToken(),
        undefined,
        "POST",
        { args, column_conditions: columnConditions },
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }

        toast.success("New column added at the end of table.");
        onSuccess({ tool: selectedTool, args, conditions: columnConditions });
        return res.json();
      })
      .catch((err) => {
        toast.error("Failed to create column. Please try again.");
        setAddColumnLoading(false);
      });
    setAddColumnLoading(false);
  }

  if (addColumnLoading) {
    return (
      <div className="flex h-full items-center justify-center gap-2 py-5">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
        loading..
      </div>
    );
  }
  return (
    <div>
      <div className="mt-6 flex flex-col gap-4">
        {selectedTool.fields.map((f) => {
          if (!Object.hasOwn(columnForm, f.id)) {
            setColumnForm((columnForm) => ({
              ...columnForm,
              [f.id]: null,
            }));
          }
          if (f.fieldType === "textarea") {
            const id = `${selectedTool.id}-${f.label}`;
            return (
              <div className={`flex flex-col gap-2`} key={f.id}>
                <label className="pl-1" htmlFor={id}>
                  {f.label}
                </label>
                <ColumnsTextArea
                  id={id}
                  placeholder={f.placeholder}
                  overrideClasses={`${errors.includes(f.id) ? "border border-red-600 hover:border-red-800" : ""} `}
                  val={columnForm[f.id] || ""}
                  setVal={(v) => {
                    if (errors.includes(f.id)) {
                      setErrors((errs) => [
                        ...errors.filter((e) => e !== f.id),
                      ]);
                    }
                    setColumnForm((columForm) => ({
                      ...columForm,
                      [f.id]: v,
                    }));
                  }}
                  columns={defaultTable?.column_list || []}
                />
              </div>
            );
          } else if (f.fieldType === "keySelect") {
            const id = `${selectedTool.id}-${f.label}`;
            const options: Column[] | string[] =
              f.options === "__COLUMN__" ? columns : f.options;
            return (
              <div className="flex flex-col gap-2" key={f.id}>
                <label
                  htmlFor={id}
                  className="block pl-1 font-medium text-gray-900 "
                >
                  {f.label}
                </label>
                <select
                  defaultValue={undefined}
                  onChange={(e) => {
                    setErrors((errs) => [...errors.filter((e) => e !== f.id)]);
                    setColumnForm((columForm) => ({
                      ...columForm,
                      [f.id]: e.target.value,
                    }));
                  }}
                  id="countries"
                  className={twMerge(
                    `block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 `,
                    `${errors.includes(f.id) ? "border border-red-600" : ""} `,
                  )}
                >
                  <option value={""}>Not selected</option>
                  {options.map((o) => {
                    const currOption =
                      typeof o === "string"
                        ? { id: o, display: o }
                        : {
                            // for tool columns, we cant just display its data_field because that contains a uuid which looks horrible
                            // so instead we will show tool's name
                            id: o.data_field,
                            display: getColumnDisplayName(o),
                          };
                    return (
                      <option value={currOption.id} key={currOption.id}>
                        {sentenceCase(currOption.display, "_")}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          }
          return <>x</>;
        })}
        <div className="mt-12">
          <ColumnConditionAdder
            key={"conditions"}
            columns={defaultTable?.column_list || []}
            conditionalColumn={conditionalColumn}
            filterForm={filterForm}
            setConditionalColumn={setConditionalColumn}
            setFilterForm={setFilterForm}
          />
        </div>
      </div>

      <IconButton
        overrideClasses="mt-4 h-9 gap-1.5 border-purple text-white  md:h-[42px]  "
        onClick={() => {
          add_column(columnForm);
        }}
        variant="fill"
        icon={<StarsIcon className="h-4 w-4 md:h-6 md:w-6" fill="white" />}
        text={
          <div className="min-w-max text-sm  md:text-lg ">Create Column</div>
        }
      />
    </div>
  );
}

export function ColumnConditionAdder({
  conditionalColumn,
  setConditionalColumn,
  columns,
  filterForm,
  setFilterForm,
}: {
  columns: Column[];
  conditionalColumn: boolean;
  setConditionalColumn: React.Dispatch<React.SetStateAction<boolean>>;
  filterForm: FilterFormField[];
  setFilterForm: React.Dispatch<React.SetStateAction<FilterFormField[]>>;
}) {
  const [randId] = useState(Math.random().toString());

  return (
    <div>
      <div className={`flex gap-2`} key={randId}>
        <input
          checked={conditionalColumn}
          onChange={(e) => {
            setConditionalColumn(e.target.checked);
          }}
          id={randId}
          type="checkbox"
          className=""
        />
        <label
          className={twMerge(
            "pl-1 text-gray-500 transition-all duration-300 hover:text-gray-800",
            `${conditionalColumn ? "text-gray-800" : ""}`,
          )}
          htmlFor={randId}
        >
          Run conditionally, only where specific conditions are true, e.g.
          "employees"=="10"
        </label>
      </div>
      {conditionalColumn && columns && (
        <ColumnConditions
          columns={columns}
          filterForm={filterForm}
          setFilterForm={setFilterForm}
        />
      )}
    </div>
  );
}

function ColumnConditions({
  columns,
  filterForm,
  setFilterForm,
}: {
  columns: Column[];
  filterForm: FilterFormField[];
  setFilterForm: React.Dispatch<React.SetStateAction<FilterFormField[]>>;
}) {
  const filterOptions: Array<{ id: FilterFormField["type"]; name: string }> = [
    { id: "equals", name: "Equals" },
    { id: "notEquals", name: "Not Equals" },
    { id: "startsWith", name: "Starts With" },
    { id: "contains", name: "Contains" },
    { id: "exists", name: "Exists" },
    { id: "greaterThan", name: "Greater Than" },
    { id: "lessThan", name: "Less Than" },
  ];
  // console.log(filterForm);

  function handleFormUpdate(
    f: FilterFormField,
    k: keyof FilterFormField,
    v: FilterFormField["key"],
  ) {
    setFilterForm((prev) =>
      getUpdatedForm({
        prev,
        updatedField: f,
        k,
        v,
      }),
    );
  }
  function handleFilterRemove(f: FilterFormField) {
    setFilterForm((prev) => {
      const updatedFilters = [...prev.filter((ff) => ff.id !== f.id)];
      return updatedFilters;
    });
  }

  return (
    <div>
      <FilterFields
        cols={columns.map((c) => ({
          name: getColumnDisplayName(c),
          id: c.data_field,
        }))}
        filterForm={filterForm}
        handleFormUpdate={handleFormUpdate}
        handleFilterRemove={handleFilterRemove}
        filterOptions={filterOptions}
      />
      <div>
        <IconButton
          overrideClasses="max-w-max"
          icon={<PlusIcon className="fill-purpleHover" />}
          text={"Add filter"}
          variant="outline"
          onClick={() => {
            setFilterForm((prev) => [
              ...prev,
              { id: Math.random().toString(), key: "", type: "", value: "" },
            ]);
          }}
        />
      </div>
    </div>
  );
}

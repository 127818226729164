import { useState } from "react";
import { ColumnForm } from "src/components/columnAdder/ColumnForm";
import { IconButton } from "src/components/elements/IconButton";
import StarsIcon from "src/components/svgs/Stars";
import { sentenceCase } from "src/components/utils";
import { KurationTool } from "src/models/tools";

export const KurationFilterDetails = ({
  selectedTool,
}: {
  selectedTool: KurationTool;
}) => {
  const [showCreateForm, setShowCreateForm] = useState(false);

  return (
    <div className="flex flex-1 flex-col">
      <div className="px-6 pb-9">
        <KurationFilterHead selectedTool={selectedTool} />
       
        {showCreateForm ? (
          <ColumnForm
            columnsFallback={[]}
            selectedTool={selectedTool}
            onSuccess={() => {
              setShowCreateForm(false);
            }}
          />
        ) : (
          <FieldsDetails selectedTool={selectedTool} />
        )}
      </div>
      {!showCreateForm && (
        <div className="mb-9 mt-auto px-6">
          <IconButton
            overrideClasses="mt-3 h-9 gap-1.5 border-purple text-white  md:h-[42px]  "
            onClick={() => {
              setShowCreateForm(true);
            }}
            variant="fill"
            icon={<StarsIcon className="h-4 w-4 md:h-6 md:w-6" fill="white" />}
            text={
              <div className="min-w-max text-sm  md:text-lg ">
                Create column
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};
export function KurationFilterHead({ selectedTool }: { selectedTool: KurationTool }) {
  return (
    <>
    <div className="flex items-center justify-between py-4">
      <div className="flex items-center gap-4">
        <div className="flex h-9  items-center gap-2">
          <div>
            <img src={selectedTool.icon} width={25} alt={selectedTool.name} />
          </div>
          <div className="text-lg">{selectedTool.name}</div>
        </div>
      </div>
    </div>
    <div className="mt-3 text-grey-100">{selectedTool.description}</div>
    </>
  );
}
const FieldsDetails = ({ selectedTool }: { selectedTool: KurationTool }) => {
  return (
    <div>
      <div className="mb-5 mt-9">Required input:</div>
      <ul className="m-0 list-none rounded-md bg-gray-200 px-4 py-6 marker:ml-2">
        {selectedTool.fields.map((f) => {
          return (
            <li className="">
              <span className="text-neutral-700">
                {sentenceCase(f.id, "_")} -{" "}
              </span>{" "}
              <span className="text-neutral-400">{f.description}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

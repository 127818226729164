import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ChatMessage from "./ChatMessage";
import InputContainer from "../../components/elements/InputContainer";
import {
  CreateAndSendMessage,
  FreeDict,
  Message,
  ResearchPlanItem,
} from "../../models/data";
import Modal from "../../components/elements/Modal";
import ContactForm from "../../components/ContactForm";
import { useAuth } from "../../context/AuthContext";
import backend_service from "../../services/backend_service";

import { toast } from "react-toastify";
import Spinner, { SimpleSpinner } from "../../components/elements/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import RequestStatus from "../RequestUpdates/RequestStatus";
import ChatToolbar from "../toolbar/ChatToolbar";
import { fetchTools, scrollToElement } from "../../components/utils";
import { useStateContext } from "../../context/StateContext";
import { PopupButton } from "@typeform/embed-react";
import useChatUpdates from "../../hooks/useChatUpdates";
import { useTutorialMode } from "../../hooks/useTutorialMode";
import useChatDetails from "src/hooks/useChatDetails";
import { FiltersLibrary } from "../filters/FiltersLibrary";
import { DataPointExplorer } from "../dataPointExplorer/DataPointExplorer";
import CustomModal from "src/components/elements/CustomModel";
import { IconButton } from "src/components/elements/IconButton";
import RocketLaunchIcon from "@heroicons/react/24/outline/RocketLaunchIcon";
import {
  ChatUpdate,
  ObjectiveGenerated,
  ResearchPlanComplete,
  ToolRecommendation,
} from "src/models/updates";
import KurationSmallIcon from "src/components/svgs/KurationSmall";
import { useQuery } from "@tanstack/react-query";
import { KurationTool } from "src/models/tools";
import {
  ArrowRightIcon,
  BoltIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import ColumnAdder from "src/components/columnAdder/ColumnAdder";
import useDefaultTable from "src/hooks/useChatTable";
import backend_services from "../../services/backend_service";

const ChatContainer = forwardRef(
  (props, ref: Ref<{ createAndSendMessage: CreateAndSendMessage }>) => {
    const [socket, setSocket] = useState<WebSocket | null>();
    const { chatType, filtersOpen, dpeState } = useStateContext();
    const [functionModal, setFunctionModal] = useState({
      isOpen: false,
      objective: "",
      requirements: [""],
    });
    const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
    const { chatId: currentOpenChat } = useParams();
    const { tutorialOpen } = useTutorialMode();

    const chatUpdates = useChatUpdates();
    const objective_update = chatUpdates.find(
      (update) => update.type === "objective_generated",
    ) as ObjectiveGenerated | undefined;
    const [messages, setMessages] = useState<Message[]>([]);

    const [isMessageStreaming, setIsMessageStreaming] = useState(false);
    const messagesContainer = useRef(null);

    const { user } = useAuth();
    const navigator = useNavigate();
    const [chatIsLoading, setChatLoading] = useState(false);

    const [inputContainerHeight, setInputContainerHeight] =
      useState<number>(87);
    const inputContainertRef = useCallback(
      (node: HTMLDivElement) => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
          let newHeight = node.getBoundingClientRect().height;
          if (newHeight !== inputContainerHeight) {
            setInputContainerHeight(newHeight);
          }
        });
        resizeObserver.observe(node);
      },
      [inputContainerHeight],
    );
    const heightVariants: { [key: number]: string } = {
      87: "h-[calc(100vh-87px)]",
      111: "h-[calc(100vh-111px)]",
      135: "h-[calc(100vh-135px)]",
      159: "h-[calc(100vh-159px)]",
      183: "h-[calc(100vh-183px)]",
      207: "h-[calc(100vh-207px)]",
      231: "h-[calc(100vh-231px)]",
      255: "h-[calc(100vh-255px)]",
      279: "h-[calc(100vh-279px)]",
      303: "h-[calc(100vh-303px)]",
    };

    const useCasePopupRef = useRef();
    const openUseCasePopup = () => (useCasePopupRef.current as any)?.open();
    useEffect(() => {
      const abortController = new AbortController();
      setMessages([]);
      if (user && currentOpenChat) {
        console.log("loading started");
        setChatLoading(true);
        backend_service
          .fetchProtectedData(
            `/get_chat_messages/?chat_id=${currentOpenChat}`,
            user.getIdToken(),
            abortController.signal,
          )
          .then((res) => {
            return res.json();
          })
          .then((res_json) => {
            setMessages(
              ((res_json["messages"] ?? []) as Message[]).filter((m) => {
                return (
                  m.role === "user" ||
                  (m.role === "assistant" && m.content != null) ||
                  (m.role === "logging" && m.type === "user_list")
                );
              }),
            );
            setChatLoading(false);
            setTimeout(
              () => scrollToElement({ containerRef: messagesContainer }),
              10,
            );
          })
          .catch((error: Error) => {
            console.log(error);
            if (!error.message.includes("aborted")) {
              toast("Failed to fetch your chats.", {
                toastId: "chatsFetchFail",
              });
              setTimeout(
                () => scrollToElement({ containerRef: messagesContainer }),
                10,
              );

              setChatLoading(false);
            }
          });
      }
      return () => {
        abortController.abort();
        setChatLoading(false);
      };
    }, [currentOpenChat, user]);

    const handleSocketMessageEventCallback = useCallback(
      function handleSocketMessageEvent(ev: MessageEvent) {
        let myStreamStringChunk = ev.data as string;
        if (
          myStreamStringChunk.startsWith("##OPENEND##ERROR_RECHARGE_REQUIRED:")
        ) {
          toast("You ran out of credits. Please recharge first and come back.");
          setIsMessageStreaming(false);
          navigator("/profile");
        } else if (myStreamStringChunk.startsWith("##OPENEND##ERROR_TOKEN:")) {
          user?.getIdToken(true);
          toast("Login expired. Please login again");
          setIsMessageStreaming(false);
        } else if (myStreamStringChunk.startsWith("##OPENEND##ERROR:")) {
          myStreamStringChunk = myStreamStringChunk.replace(
            "##OPENEND##ERROR:",
            "",
          );
          toast.error(`An error occurred: ${myStreamStringChunk}`);
          setIsMessageStreaming(false);
          alert(`An error occurred ${myStreamStringChunk}`);
        } else if (myStreamStringChunk.startsWith("##OPENEND##FUNC:")) {
          let func_call = myStreamStringChunk.replace("##OPENEND##FUNC:", "");
          const func_call_json = JSON.parse(func_call);
          setFunctionModal({
            isOpen: true,
            objective:
              func_call_json["function_call"]["arguments"]["objective"],
            requirements:
              func_call_json["function_call"]["arguments"]["requirements"],
          });
          setIsMessageStreaming(false);
        } else if (
          myStreamStringChunk.startsWith("##OPENEND##FORM_SUCESSFUL")
        ) {
          toast.success("All done 🥳");
          setIsMessageStreaming(false);
        } else if (myStreamStringChunk.startsWith("##OPENEND##USECASEWRONG")) {
          setIsMessageStreaming(false);
          // window.open("https://kurationai.typeform.com/notdoable", "_blank");
          openUseCasePopup();
        } else if (myStreamStringChunk.startsWith("##OPENEND##")) {
          let open_most_recent_by_id = myStreamStringChunk.split("_")[1];
          if (open_most_recent_by_id && open_most_recent_by_id !== "LLM") {
            navigator("/chat/" + open_most_recent_by_id);
          }

          setIsMessageStreaming(false);
        } else {
          setMessages((prev) => {
            let newMessages = [...prev];
            let lastMess = newMessages[newMessages.length - 1];
            newMessages[newMessages.length - 1] = {
              ...lastMess,
              content: lastMess?.content + myStreamStringChunk,
            };
            return newMessages;
          });
        }
      },
      [navigator, user],
    );

    useEffect(() => {
      if (socket && socket.readyState === socket.OPEN) {
        return;
      }
      let ws: WebSocket;
      try {
        // setConnectionState("connecting");
        ws = new WebSocket(backend_service.ws_url);

        if (ws) {
          ws.onmessage = (ev: MessageEvent) => {
            handleSocketMessageEventCallback(ev);
          };
          ws.onerror = (ev: Event) => {
            console.log(`socket error ${ev}`, ev);
            setSocket(null);
          };
          ws.onclose = (ev: CloseEvent) => {
            console.log(`socket error ${ev}`, ev);
            setSocket(null);
          };
          ws.onopen = (ev: Event) => {
            setSocket(ws);
          };
        }
      } catch (error) {
        console.log(`socket error ${error}`, error);
        alert(`socket error ${error}`);
      }
      return () => {};
    }, [handleSocketMessageEventCallback, socket]);

    const addUserMessage = (
      userMess: string,
      userUploadedJson?: Record<string, string>[] | null,
    ) => {
      if (isMessageStreaming) {
        console.log("A message is already in progress.");
        return;
      }
      const listMess: Message = {
        role: "logging",
        type: "user_list",
        data: userUploadedJson ? userUploadedJson : [],
        time: new Date().toLocaleTimeString(),
        content: null,
      };
      setMessages((prev) => {
        return [
          ...prev,
          ...(userUploadedJson ? [listMess] : []),
          {
            content: userMess,
            role: "user",
            time: new Date().toLocaleTimeString(),
          },
          {
            content: "",
            role: "assistant",
            time: new Date().toLocaleTimeString(),
          },
        ];
      });
    };
    const createAndSendMessage: CreateAndSendMessage = async (
      messageText: string,
      userUploadedJson?: Record<string, string>[] | null,
      hiddenMessage: boolean = false,
      type: "contact_form" | "manual_search" | "prompt" = "prompt",
      visualText?: string,
    ) => {
      let newChatType = chatType;
      if (userUploadedJson) {
        newChatType = "upload";
      }
      if (!hiddenMessage) addUserMessage(messageText, userUploadedJson);
      if (hiddenMessage && visualText && visualText?.length > 0) {
        addUserMessage(visualText, userUploadedJson);
      }
      if (type === "contact_form" || type === "manual_search") {
        //for contact form, the messageText would actual be stringified json,
        // so we dent wanna restringing it so we first parse it
        messageText = JSON.parse(messageText);
      }
      let data_to_send = JSON.stringify({
        type: type,
        data: messageText,
        chat_id: currentOpenChat,
        chat_type: newChatType,
        ...(userUploadedJson ? { uploaded_list: userUploadedJson } : {}),
        idToken: await user?.getIdToken(),
      });

      try {
        sendMessage(data_to_send);
      } catch (error) {
        console.log(`socket error ${error}`, error);
        alert(`socket error ${error}`);
      }
      // if (!hiddenMessage) createStreamingMessage();
    };
    function sendMessage(text: string) {
      try {
        if (socket && socket.readyState === socket.OPEN) {
          socket?.send(text);
          setIsMessageStreaming(true);
          console.log("MESSAGE IS NOW STREAMING");
        } else {
          toast.error("Problem connecting to server. Refresh page.");
        }
      } catch (error) {
        console.log(`socket error ${error}`, error);
        // alert(`socket error ${error}`);
      }
    }

    useImperativeHandle(ref, () => ({
      createAndSendMessage,
    }));
    console.log(functionModal.isOpen && objective_update && (objective_update).status !== "completed")
    return (
      <div className="relative h-full flex-grow">
        {((!chatIsLoading && messages.length === 0) || tutorialOpen) && (
          <div
            className={`h-full ${tutorialOpen && messages.length > 0 ? "md:hidden" : ""}`}
          >
            <ChatToolbar createAndSendMessage={createAndSendMessage} />
          </div>
        )}
        {filtersOpen && currentOpenChat && !tutorialOpen && (
          <div className="h-full ">
            <FiltersLibrary />
          </div>
        )}
        {dpeState.isOpen && currentOpenChat && !tutorialOpen && (
          <div className="absolute inset-0 h-full bg-white">
            <DataPointExplorer dpeState={dpeState} />
          </div>
        )}
        {!filtersOpen && !dpeState.isOpen && (
          <div
            className={`flex flex-col ${tutorialOpen ? "hidden md:block" : ""}`}
          >
            {/* {chatUpdates.length > 0 && (
              <ChatStatusIndictor
                latestChatUpdate={chatUpdates[chatUpdates.length - 1]}
              />
            )} */}
            {chatIsLoading && (
              <Spinner
                text=""
                onWhiteBackground
                // styleClasses={[`${sidebarOpen ? "md:ml-[300px]" : ""}`]}
              />
            )}
            {messages.length > 0 && (
              <div
                className={`flex flex-grow flex-col overflow-auto overflow-y-auto transition-transform duration-300 ease-out ${heightVariants[inputContainerHeight]} bg-white p-5 pt-0 md:px-6`}
              >
                {messages.map((mess, index) => (
                  <ChatMessage
                    key={index.toString() + mess.time}
                    message={mess}
                    userName={
                      mess.role === "user" || mess.type === "user_list"
                        ? `${user?.displayName ?? ""}`
                        : "Kuration AI"
                    }
                    isLast={messages.length - 1 === index}
                  />
                ))}
                {/* Add more messages */}
                {!chatIsLoading && chatUpdates?.length > 0 && (
                  <RequestStatus
                    key={"RequestStatus"}
                    chatUpdates={chatUpdates}
                  />
                )}
              </div>
            )}
            <div ref={messagesContainer} className="invisible h-0 w-0"></div>
            <PopupButton className="hidden" id="JHLfuuD9">
              Use Case Form
            </PopupButton>
            {messages.length > 0 && (
              <div
                ref={inputContainertRef}
                className="fixed bottom-0 w-full bg-white p-5 pt-0 md:w-[560px] md:p-6 md:pt-0"
              >
                <InputContainer
                  isMessageAllowed={
                    !!socket &&
                    socket.readyState === socket.OPEN &&
                    !isMessageStreaming
                  }
                  addUserMessage={createAndSendMessage}
                />
              </div>
            )}
            {functionModal.isOpen && objective_update && (
              <CustomModal
                isOpen={functionModal.isOpen}
                onClose={() => {
                  setFunctionModal({
                    isOpen: false,
                    objective: "",
                    requirements: [],
                  });
                  console.log("closing modal");
                }}
              >
                <div className="z-50 flex h-full items-center justify-center">
                  <ObjectiveModal
                    objectiveUpdate={objective_update as ObjectiveGenerated}
                    createAndSendMessage={createAndSendMessage}
                    setFunctionModal={setFunctionModal}
                    setIsFormModalOpen={setIsFormModalOpen}
                  />
                </div>
              </CustomModal>
            )}
            {isFormModalOpen && (
              <Modal
                isOpen={isFormModalOpen}
                header={"Our bots are working to complete your results."}
                body={
                  <ContactForm
                    onLaunch={() => {
                      setIsFormModalOpen(false);
                      setFunctionModal({
                        isOpen: false,
                        objective: "",
                        requirements: [],
                      });
                    }}
                    createAndSendMessage={createAndSendMessage}
                  />
                }
                actions={[]}
                onClose={() => {
                  setIsFormModalOpen(false);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  },
);
export function ObjectiveModal({
  objectiveUpdate,
  createAndSendMessage,
  setFunctionModal,
  setIsFormModalOpen,
}: {
  objectiveUpdate: ObjectiveGenerated;
  createAndSendMessage: (message: string) => void;
  setFunctionModal: (modal: {
    isOpen: boolean;
    objective: string;
    requirements: string[];
  }) => void;
  setIsFormModalOpen: (isOpen: boolean) => void;
}) {
  const chatUpdates = useChatUpdates();
  console.log("chatUpdates", chatUpdates);
  const { user } = useAuth();
  const { data: tools } = useQuery({
    queryKey: ["tools"],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return user && fetchTools(user?.getIdToken());
    },
  });
  const researchPlanUpdate = chatUpdates
    .filter((update) => update.type === "research_plan_complete")
    .at(-1) as ResearchPlanComplete | undefined;

  const chatToolReccs: (ToolRecommendation & { tool?: KurationTool })[] =
    chatUpdates
      .filter((update) => update.type === "tool_recommendation")
      .filter(
        (update) =>
          (update as ToolRecommendation).data.research_plan_id ===
          researchPlanUpdate?.research_plan_id,
      )
      .map((t: any) => ({
        ...t,
        tool: tools?.find((tool) => tool.id === t.data?.id),
      }));
  const isToolGenerationComplete =
    chatToolReccs.length === researchPlanUpdate?.data.length;
  const { chatId } = useParams();
  function createColumns(isTrial: boolean) {
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/column/convert_to_columns?is_trial=${isTrial}`,
        user?.getIdToken(),
        undefined,
        "POST",
        {
          research_plan_id: researchPlanUpdate?.research_plan_id,
          objective_update_id: objectiveUpdate.id,  
        }
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }
        return res.json();
      })
      .then((data) => {
        console.log("data", data);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error creating columns");
      });
  }
  function tryBot() {
    setIsFormModalOpen(true);
    createColumns(true);
  }
  function launchBot() {
    setIsFormModalOpen(true);
    createColumns(false);
  }
  return (
    <div
      className="w-full max-w-5xl rounded-lg bg-white p-6"
      onClick={(e) => e.stopPropagation()}
    >
      <h2 className="mb-4 flex items-center gap-2 text-2xl font-bold text-gray-800">
        <KurationSmallIcon width={20} /> Kuration AI: Requirements Complete
      </h2>
      <div className="mb-6">
        <h3 className="mb-2 text-lg font-semibold text-gray-700">Objective</h3>
        <p className="text-gray-600">{objectiveUpdate.data.objective}</p>
      </div>
      <div className="mb-6 max-h-[600px] overflow-auto">
        <h3 className="mb-2 text-lg font-semibold text-gray-700">
          Data points and recommended tools...
        </h3>
        <ul className="m-0 flex max-h-[800px] min-h-[300px] list-none flex-col  gap-4  overflow-auto text-gray-600">
          {!researchPlanUpdate && (
            <div className="flex items-center justify-center gap-2">
              <SimpleSpinner radius={20} overrideClasses="border-black" />
              <span>Generating columns and tools...</span>
            </div>
          )}
          {researchPlanUpdate?.data.map(
            (researchItem: ResearchPlanItem, index: number) => {
              const toolRecc = chatToolReccs.find(
                (tool) => tool.data.research_plan_item_index === index,
              );
              return (
                <li
                  key={index}
                  className="grid min-h-[45px] grid-cols-12 items-center"
                >
                  <div className="col-span-3">
                    <ResearchPlanItemDisplay researchPlanItem={researchItem} />
                  </div>
                  <div className="col-span-1 flex justify-center">
                    <ArrowRightIcon className="h-5 w-5 text-gray-300  " />
                  </div>
                  <div className="col-span-8">
                    {toolRecc ? (
                      <ResearchPlanItemToolDisplay toolRecc={toolRecc} />
                    ) : (
                      <SimpleSpinner
                        radius={15}
                        overrideClasses="border-black"
                      />
                    )}
                  </div>
                </li>
              );
            },
          )}
        </ul>
      </div>
      {isToolGenerationComplete && <SimpleSpinner />}
      <div className="flex justify-end space-x-4">
        <IconButton
          icon={<BoltIcon className="h-5 w-5" />}
          variant="outline"
          onClick={() => {
            tryBot()
          }}
          text="Try"
          overrideClasses="w-min"
        />
        <IconButton
          icon={<RocketLaunchIcon className="h-5 w-5" />}
          variant="fill"
          onClick={() => {
            launchBot()
          }}
          text="Launch"
          overrideClasses="w-min"
        />
      </div>
    </div>
  );
}
export default ChatContainer;
function DeleteToolRecc({ toolReccId }: { toolReccId: string }) {
  const [isDeletingTool, setIsDeletingTool] = useState(false);
  function deleteTool(reccId: string) {
    setIsDeletingTool(true);
    console.log("deleting tool", reccId);
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/updates/${reccId}/user_selection`,
        user?.getIdToken(),
        undefined,
        "DELETE",
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }
        return res.json();
      })
      .then((data) => {
        console.log("data", data);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error deleting tool");
      })
      .finally(() => {
        setIsDeletingTool(false);
      });
  }
  const { chatId } = useParams();
  const { user } = useAuth();
  return (
    <button
      type="button"
      title="Close Add Column Menu"
      className="flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple"
      onClick={() => {
        deleteTool(toolReccId);
      }}
    >
      {isDeletingTool ? (
        <SimpleSpinner radius={15} overrideClasses="border-black" />
      ) : (
        <TrashIcon className="h-5 w-5" />
      )}
    </button>
  );
}
function ResearchPlanItemToolDisplay({
  toolRecc,
}: {
  toolRecc: ToolRecommendation & { tool?: KurationTool };
}) {
  const [showColumnAdder, setShowColumnAdder] = useState(false);
  const [savingTool, setSavingTool] = useState(false);
  const { chatId } = useParams();
  const { chatDetails } = useChatDetails(chatId || null);
  const { data: table } = useDefaultTable(chatDetails?.table_id);
  const { user } = useAuth();
  function addToolToRecc(
    tool: KurationTool,
    args: FreeDict,
    conditions: FreeDict,
  ) {
    setSavingTool(true);
    const toolReccWithTool = {
      tool,
      args,
      conditions,
    };
    console.log("toolReccWithTool", toolReccWithTool);
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/updates/${toolRecc.id}`,
        user?.getIdToken(),
        undefined,
        "POST",
        {
          tool_id: tool.id,
          args_and_conditions: { args, column_conditions: conditions },
        },
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }
        return res.json();
      })
      .then((data) => {
        console.log("data", data);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error adding tool to research plan");
      })
      .finally(() => {
        setSavingTool(false);
        setShowColumnAdder(false);
      });
  }
  if (!toolRecc.tool) {
    if (toolRecc.user_selection) {
      return (
        <ResearchPlanItemToolHeadDisplay
          toolReccId={toolRecc.id}
          tool={toolRecc.user_selection.selected_tool}
          args={toolRecc.user_selection.args}
          conditions={toolRecc.user_selection.column_conditions}
        />
      );
    }
    return (
      <div className="flex items-center gap-2">
        <IconButton
          icon={<PlusIcon className="h-5 w-5" />}
          variant="outline"
          text="Select a tool manually"
          overrideClasses="max-w-max"
          onClick={() => {
            setShowColumnAdder(true);
          }}
        />
        {showColumnAdder && (
          <CustomModal
            isOpen={true}
            onClose={() => {
              setShowColumnAdder(false);
            }}
          >
            <div className="z-50 flex h-full items-center justify-center">
              {savingTool && (
                <SimpleSpinner radius={20} overrideClasses="border-black" />
              )}
              {!savingTool && (
                <ColumnAdder
                  preventColumnCreation={true}
                  columns={table?.column_list || []}
                  onClose={() => {
                    setShowColumnAdder(false);
                  }}
                  onSuccess={({ tool, args, conditions }) => {
                    console.log("tool", tool);
                    console.log("args", args);
                    console.log("conditions", conditions);
                    addToolToRecc(tool, args, conditions);
                  }}
                />
              )}
            </div>
          </CustomModal>
        )}
      </div>
    );
  }
  return (
    <ResearchPlanItemToolHeadDisplay
      tool={toolRecc.tool}
      args={toolRecc.data.args}
      conditions={[]}
      toolReccId={toolRecc.id}
    />
  );
}
function ResearchPlanItemToolHeadDisplay({
  tool,
  args,
  conditions,
  toolReccId,
}: {
  tool: KurationTool;
  args: FreeDict;
  conditions: FreeDict[];
  toolReccId: string;
}) {
  return (
    <div className="group flex items-center gap-2">
      <div>
        <img
          className="rounded-full"
          src={tool?.icon}
          width={25}
          alt={tool?.name}
        />
      </div>
      <div className="text-lg">
        {tool.display_field ? args[tool.display_field] : tool.name}
      </div>
      <div className="opacity-0 transition-all duration-300 group-hover:opacity-100">
        <DeleteToolRecc toolReccId={toolReccId} />
      </div>
    </div>
  );
}
function ResearchPlanItemDisplay({
  researchPlanItem,
}: {
  researchPlanItem: ResearchPlanItem;
}) {
  return (
    <div>
      <div className="text-sm text-gray-500">
        <div className="group relative">
          <span>{researchPlanItem.data_field}</span>
          <div className="fixed z-10 hidden w-64 origin-top-left rounded-md bg-white p-2 shadow-lg group-hover:block">
            <ul className="m-0 list-none p-2">
              {Object.entries(researchPlanItem).map(
                ([key, value]) =>
                  key !== "data_field" && (
                    <li key={key} className="text-xs">
                      <strong>{key.split("_").join(" ")}:</strong> {value}
                    </li>
                  ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export function ChatStatusIndictor({
  latestChatUpdate,
}: {
  latestChatUpdate: ChatUpdate;
}) {
  const { chatId } = useParams();
  const { chatDetails } = useChatDetails(chatId || null);
  if (latestChatUpdate.type === "final_list_complete") {
    return <></>;
  }
  return (
    <div
      className={` fixed right-0 z-40 flex  w-full items-center justify-center bg-zblue py-2 font-mono text-xs text-white shadow-md md:w-[560px] md:text-base`}
    >
      {latestChatUpdate.type === "objective_generated" && (
        <div className="flex items-center justify-center gap-2">
          <SimpleSpinner />
          <span>Bots are gathering companies from knowledge base</span>
        </div>
      )}
      {latestChatUpdate.type === "initial_list_complete" && (
        <div className="flex items-center justify-center gap-2">
          <SimpleSpinner />
          <span>
            Bots are researching companies
            {chatDetails && (
              <span className="font-extrabold"> {chatDetails.progress}% </span>
            )}
          </span>
        </div>
      )}
    </div>
  );
}
